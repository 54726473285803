<template>  
    <v-card height="100%">
        <div class="text-center">
            <b><p>Dados {{ this.$store.state.user.name }}</p></b>
        </div>
        <div class="row"> 
            <v-card class="mx-auto mb-1 col-12" outlined>
                <v-list-item three-line>
                    <v-list-item-content class="text-center">
                        <v-list-item-title class="mb-5"><b>Total multas + número</b></v-list-item-title>
                        <v-list-item-subtitle v-if="$store.state.user.role === 'admin'" class="valorsize"><b>{{ somamultasnumerototal.toFixed(2) }} €</b></v-list-item-subtitle>
                        <v-list-item-subtitle v-else class="valorsize"><b>{{ jogadormultasnumero.toFixed(2) }} €</b></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </div>
        <div class="row">
            <v-card class="mx-auto col-6" outlined>
                <v-list-item three-line>
                    <v-list-item-content class="text-center">
                        <v-list-item-title class="mb-5"><b>Pago</b></v-list-item-title>
                        <v-list-item-subtitle v-if="$store.state.user.role === 'admin'" class="valorsize"><b>{{ somapagamentostotal.toFixed(2) }} €</b></v-list-item-subtitle>
                        <v-list-item-subtitle v-else class="valorsize"><b>{{ jogadorpagamentos.toFixed(2) }} €</b></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card class="mx-auto col-6" outlined>
                <v-list-item three-line>
                    <v-list-item-content class="text-center">
                        <v-list-item-title class="mb-5"><b>Em divida</b></v-list-item-title>
                            <template v-if="$store.state.user.role === 'admin'">
                                <v-list-item-subtitle class="valorsize" v-if="somadividastotal < 0"><b class="Tred">{{ somadividastotal.toFixed(2) }} €</b></v-list-item-subtitle>
                                <v-list-item-subtitle class="valorsize" v-else><b class="Tgreen">{{ somadividastotal.toFixed(2) }} €</b></v-list-item-subtitle>
                            </template>
                            <template v-else>
                                <v-list-item-subtitle class="valorsize" v-if="jogadoremdivida < 0"><b class="Tred">{{ jogadoremdivida.toFixed(2) }} €</b></v-list-item-subtitle>
                                <v-list-item-subtitle class="valorsize" v-else><b class="Tgreen">{{ jogadoremdivida.toFixed(2) }} €</b></v-list-item-subtitle>
                            </template>
                    </v-list-item-content>
                </v-list-item>
            </v-card>   
        </div>
    </v-card>
</template>
  
<script>
import ServiceMultasJogadores from '@/services/ServiceMultasJogadores'
import ServiceUsers from '@/services/ServiceUsers'
import ServicePagamentos from '@/services/ServicePagamentos'
export default {
    name: "MyHome",
    data() {
        return {
            
            multasjogador: [],
            user: [],
            users: [],
            multastodas: [],
            /*Multas jogador individual*/
            jogadoremdivida: 0,
            jogadormultas: 0,
            jogadorpagamentos: 0,
            jogadornumero: 0,
            jogadormultasnumero: 0,
            /*Multas todos*/
            somadividastotal: 0,
            somamultastotal: 0,
            somapagamentostotal: 0,
            somanumerostotal: 0,
            somamultasnumerototal: 0,
            

        };
    },

    created () {
        this.initialize()
    },

    methods: {
            async initialize () {
                if (this.$store.state.user.role === 'admin') {
                    /*Multas de todos*/
                    this.users = (await ServiceUsers.findAllNaoAdmin()).data
                    this.multastodas = (await ServiceMultasJogadores.findAllValoresMultas()).data
                    this.pagamentostodos = (await ServicePagamentos.findAll()).data  

                    //numero
                    for (let i = 0; i < this.users.length; i++) {
                        this.somanumerostotal = this.somanumerostotal + parseFloat(this.users[i].numero?.valor)               
                    }

                    //multas
                    for (let i = 0; i < this.multastodas.length; i++) {
                        this.somamultastotal = this.somamultastotal + parseFloat(this.multastodas[i].multa.valor)
                    }

                    this.somamultasnumerototal = this.somanumerostotal + this.somamultastotal

                    //pagamentos
                    for (let i = 0; i < this.pagamentostodos.length; i++) {
                        this.somapagamentostotal = this.somapagamentostotal + parseFloat(this.pagamentostodos[i].valor)                  
                    }

                    //em divida
                    this.somadividastotal = this.somapagamentostotal - (this.somamultastotal + this.somanumerostotal)
                } else {
                    /*Utilizador logado*/
                    this.multasjogador = (await ServiceMultasJogadores.findAllUserLogado(this.$store.state.user.id)).data
                    this.pagamentosjogador = (await ServicePagamentos.findAllUserLogado(this.$store.state.user.id)).data
                    this.user = (await ServiceUsers.findAllUser(this.$store.state.user.id)).data
                    
                    //numero
                    for (let i = 0; i < this.user.length; i++) {
                        this.jogadornumero = this.jogadornumero + parseFloat(this.user[i].numero?.valor)                  
                    }

                    //multas
                    for (let i = 0; i < this.multasjogador.length; i++) {
                        this.jogadormultas = this.jogadormultas + parseFloat(this.multasjogador[i].multa.valor)
                    }

                    this.jogadormultasnumero = this.jogadormultas + this.jogadornumero

                    //pagamentos
                    for (let i = 0; i < this.pagamentosjogador.length; i++) {
                        this.jogadorpagamentos = this.jogadorpagamentos + parseFloat(this.pagamentosjogador[i].valor)                  
                    }

                    //em divida
                    this.jogadoremdivida = this.jogadorpagamentos - (this.jogadormultas + this.jogadornumero)
                }

                
            }  
    }
}
</script>

<style>
.valorsize {
    font-size: 30px;
}
</style>